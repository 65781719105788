import cookieService from '@/services/authent/cookie';
import { UserInfo } from '@/types';

const USER_INFO_COOKIE = 'userInfoFront';

const userInfoService = {
	// Sauvegarde un cookie avec les infos de l'utilisateur
	save(userInfo: UserInfo, expiration: number): void {
		cookieService.set(USER_INFO_COOKIE, JSON.stringify(userInfo), expiration);
	},

	// Supprime un cookie avec les infos utilisateurs
	delete(): void {
		cookieService.remove(USER_INFO_COOKIE);
	},

	// Détermine si le cookie utilisateur existe
	exists(): boolean {
		return cookieService.exists(USER_INFO_COOKIE);
	}
};

export default userInfoService;
