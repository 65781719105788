import {
	mdiConnection,
	mdiEmail,
	mdiClose,
	mdiCheck,
	mdiEye,
	mdiEyeOff,
	mdiCloseCircle,
	mdiCheckCircle,
	mdiCalendar,
	mdiAccount,
	mdiAlert,
	mdiDownload,
	mdiPlus,
	mdiOpenInApp,
	mdiArrowRight,
	mdiArrowLeft,
	mdiAlphaSCircleOutline,
	mdiAccountVoice,
	mdiTextSearchVariant,
	mdiCalendarMonth,
	mdiReload,
	mdiPrinter
} from '@mdi/js';

const icons = {
	connectionIcon: mdiConnection,
	emailIcon: mdiEmail,
	nonValidIcon: mdiClose,
	validIcon: mdiCheck,
	eyeIcon: mdiEye,
	eyeOffIcon: mdiEyeOff,
	errorTokenIcon: mdiCloseCircle,
	confirmPasswordChangeIcon: mdiCheckCircle,
	seanceIcon: mdiCalendar,
	profilIcon: mdiAccount,
	warningIcon: mdiAlert,
	downloadIcon: mdiDownload,
	addIcon: mdiPlus,
	openInAppIcon: mdiOpenInApp,
	nextArrowIcon: mdiArrowRight,
	backArrowIcon: mdiArrowLeft,
	avisSimpleIcon: mdiAlphaSCircleOutline,
	talkIcon: mdiAccountVoice,
	visualizeIcon: mdiTextSearchVariant,
	calendrierIcon: mdiCalendarMonth,
	reloadIcon: mdiReload,
	printIcon: mdiPrinter
};

export default icons;
