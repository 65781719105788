import cookieService from '@/services/authent/cookie';
import { Jwt, UserInfo } from '@/types';

const TOKEN_COOKIE = 'tokenFront';

const tokenService = {
	/**
	 * Décode un JSON Web Token et retourne son contenu sous forme d'objet
	 *
	 * @param {String} token Le JSON Web Token
	 * @returns {Object} Le contenu du JSON Web Token
	 */
	parseJwt(token: string): Jwt {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map((c) => {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return JSON.parse(jsonPayload);
	},

	/**
	 * Sauvegarde le token
	 * @param token Le token à sauvegarder
	 * @returns {Object, number} L'utilisateur connecté et le timestamp d'expiration
	 */
	save(token: string): { user: UserInfo; expiration: number } {
		const jwt: Jwt = tokenService.parseJwt(token);
		const expiration = jwt.exp;
		const user: UserInfo = {
			'id': jwt.id,
			'nom': jwt.nom,
			'prenom': jwt.prenom
		};

		cookieService.set(TOKEN_COOKIE, token, expiration);

		return { user, expiration };
	},

	/**
	 * Supprime le token
	 */
	delete(): void {
		cookieService.remove(TOKEN_COOKIE);
	},

	/**
	 * Vérifie si le token est expiré
	 * @returns {Boolean} Vrai si le token est expiré ou n'est pas présent
	 */
	isExpired(): boolean {
		const token = cookieService.get(TOKEN_COOKIE);

		if (token) {
			const jwt: Jwt = tokenService.parseJwt(token);

			return jwt && (Date.now() >= jwt.exp * 1000);
		}

		return true;
	}
};

export default tokenService;
