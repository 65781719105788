// Import Vuetify theme from Design Tokens
import { lightTheme } from '@cnamts/design-tokens/src/colors';

export const light = {
	...lightTheme,
	secondary: '#0c419a',
	tertiary: '#006386'
};

export const dark = {
	primary: '#1976D2',
	secondary: '#1976D2',
	dark: '#323535',
	black: '#1E1E1E',
	accent: '#5e709a',
	tertiary: '#006386'
};

/** Custom Vuetify color theme */
export const themeColors = {
	light: light,
	dark: dark
};
