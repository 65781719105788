<template>
	<!-- VApp is an element which is required to use Vuetify. @see https://vuetifyjs.com/en/ for help -->
	<VApp v-cloak>
		<AppHeader style="z-index: 6;" />

		<NotificationBar />

		<VSnackbar
			v-model="displayBandeau"
			top
			color="warning"
			multi-line
			:timeout="timeoutBandeau"
		>
			{{ messageBandeau }}

			<template #action="{ attrs }">
				<VBtn
					color="white"
					text
					v-bind="attrs"
					@click="displayBandeau = false"
				>
					Fermer
				</VBtn>
			</template>
		</VSnackbar>

		<VMain class="dark">
			<!-- Transition between routes -->
			<transition
				name="fade"
				mode="out-in"
			>
				<!-- Load the component defined in the router for the current route -->
				<RouterView />
			</transition>
		</VMain>

		<AppFooter v-if="!$maintenanceEnabled" />
	</VApp>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import { Meta, MetaInfo } from '@/decorators';

	// Static import for faster rendering
	import { AppHeader, AppFooter } from '@/components/layout';

	/** App is the main component */
	@Component({
		components: {
			AppHeader,
			AppFooter
		}
	})
	export default class App extends Vue {
		displayBandeau = false;
		messageBandeau: string | null = null;
		timeoutBandeau: number | null = null;
		/** The meta informations of the route */
		/* istanbul ignore next */
		@Meta
		metaInfo(): MetaInfo {
			return {
				// If a sub-components does not specifies metaInfo.title, this title will be used
				title: 'Ihm-ps',
				// All titles will be injected into this template
				titleTemplate: '%s | CONCERT-MP',
				meta: [
					{
						name: 'description',
						vmid: 'description', /** Used to avoid duplicated tags @see https://vue-meta.nuxtjs.org/faq/#unique-metadata */
						content: 'IHM PS de l\'application ConcertMP.'
					}
				]
			};
		}

		mounted(): void {
			this.displayBandeau = window.DISPLAY_BANDEAU;
			this.messageBandeau = window.MESSAGE_BANDEAU;
			this.timeoutBandeau = window.TIMEOUT_BANDEAU;
		}
	}
</script>

<style lang="scss">
	$color-pack: false;

	@import '~vuetify/src/styles/main.sass';
</style>
