// Disable return types for import functions
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** @see https://router.vuejs.org/fr/essentials/navigation.html for help */

import Home from '@/views/Home.vue';

export const routes = [
	{
		path: '',
		name: 'home',
		component: Home,
		redirect: '/seances',
		children: [
			{
				path: '/seances',
				name: 'seances',
				component: () => import(
					/* webpackChunkName: "seances" */
					'@/views/Seances.vue'
				)
			},
			{
				path: '/calendrier',
				name: 'calendrier',
				component: () => import(
					/* webpackChunkName: "calendrier" */
					'@/views/Calendrier.vue'
				)
			},
			{
				path: '/profil',
				name: 'profil',
				component: () => import(
					/* webpackChunkName: "profil" */
					'@/views/Profil.vue'
				)
			}
		]
	},

	{
		path: '/maintenance',
		name: 'maintenance',
		component: () => import(
			/* webpackChunkName: "maintenance" */
			'@/views/Maintenance.vue'
		)
	},
	{
		path: '/connexion',
		name: 'login',
		component: () => import(
			/* webpackChunkName: "login" */
			'@/views/Login.vue'
		)
	},
	{
		path: '/deconnecte',
		name: 'loggedOut',
		component: () => import(
			/* webpackChunkName: "logged-out" */
			'@/views/LoggedOut.vue'
		)
	},
	{
		path: '*', // Everything that doesn't match a route
		name: 'notFound',
		component: () => import(
			/* webpackChunkName: "not-found" */
			'@/views/NotFound.vue'
		)
	},
	{
		path: '',
		name: 'seance',
		props: true,
		component: () => import(
			/* webpackChunkName: "seance" */
			'@/views/seance/Seance.vue'
		),
		children: [
			{
				path: '/seance/:seanceId/informations',
				name: 'seanceInformations',
				component: () => import(
					/* webpackChunkName: "seanceInformations" */
					'@/views/seance/SeanceInformations.vue'
				)
			}
		]
	},
	{
		path: '',
		name: 'dossier',
		props: true,
		component: () => import(
			/* webpackChunkName: "dossier" */
			'@/views/dossier/Dossier.vue'
		),
		children: [
			{
				path: '/dossier/:dossierId/informations',
				name: 'dossierInformations',
				component: () => import(
					/* webpackChunkName: "dossierInformations" */
					'@/views/dossier/DossierInformations.vue'
				)
			},
			{
				path: '/dossier/:dossierId/preparation-avis',
				name: 'dossierPreparationAvis',
				component: () => import(
					/* webpackChunkName: "dossierPreparationAvis" */
					'@/views/dossier/DossierPreparationAvis.vue'
				)
			},
			{
				path: '/dossier/:dossierId/pieces',
				name: 'dossierPieces',
				component: () => import(
					/* webpackChunkName: "dossierPieces" */
					'@/views/dossier/DossierPieces.vue'
				)
			}
		]
	},
	{
		path: '/psc-callback',
		name: 'pscCallback',
		component: () => import(
			/* webpackChunkName: "callback" */
			'@/views/PscCallback.vue'
		)
	}
];
