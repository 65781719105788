import { Route } from 'vue-router';
import { Next } from '@cnamts/vue-dot/src/types';
import cookieService from '@/services/authent/cookie';
import tokenService from '@/services/authent/token';
import userInfoService from '@/services/authent/userInfo';
import { store } from '@/store';
import { LocalStorageUtility } from '@cnamts/vue-dot/src/helpers/localStorageUtility';
import { Jwt, UserInfo } from '@/types';

let isTimerStarted = false;

export function getCounterDuration(): number {
	const token = cookieService.get('tokenFront');

	if (!token) {
		return 0;
	}

	const jwt: Jwt = tokenService.parseJwt(token);
	const expTimestamp = jwt.exp;
	const expirationDate = new Date(expTimestamp * 1000);
	const currentDate = new Date();
	const timeDifferenceMilliseconds = +expirationDate - +currentDate;

	return Math.floor(timeDifferenceMilliseconds / (1000 * 60));
}

export function updateAppState(user: null | UserInfo, logged: boolean, loaded: boolean): void {
    store.dispatch('appState/updateUser', user);
    store.dispatch('appState/updateLogged', logged);
    store.dispatch('appState/updateLoaded', loaded);
}

export async function authenticate(to: Route, from: Route, next: Next): Promise<void> {
	const currentRoute = to.name ? to.name.toString() : '';
	// Noms des routes ne nécessitant pas d'authentification
	const publicPages = ['login', 'loggedOut', 'maintenance', 'notFound'];
	const authenticationRequired = !publicPages.includes(currentRoute);
	const isLoggedIn = userInfoService.exists();
	const isTokenExpired = tokenService.isExpired();
	const localStorageUtility = new LocalStorageUtility();

	if (isTokenExpired) {
		updateAppState(null, false, false);
		tokenService.delete();
		userInfoService.delete();
	}

	if (authenticationRequired && !isLoggedIn) {
		updateAppState(null, false, false);

		localStorageUtility.setItem('route-to-redirect', { name: to.name, params: to.params, query: to.query });

		if (currentRoute !== 'login') {
            next({ name: 'login' });
        } else {
			next();
		}
	}

	store.dispatch('appState/updateCounterDuration', store.getters['appState/getTimeLimit']);

	if (!isTimerStarted) {
		isTimerStarted = true;
		setInterval(() => {
			store.dispatch('appState/updateCounterDuration', getCounterDuration());
		}, 1000);
	}

	next();
}
