<template>
	<VTabs
		class="elevation-2"
		background-color="primary"
		dark
	>
		<VTab
			v-for="(item, index) in navigationItems"
			:key="index"
			:to="{ name: item.to }"
		>
			<VIcon class="mr-2">
				{{ item.icon }}
			</VIcon>
			{{ item.label }}
		</VTab>
	</VTabs>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import icons from '@/types/icons';

	interface NavigationItem {
		label: string;
		to: string;
		icon: string;
	}

	@Component
	export default class MainNavigation extends Vue {
		get navigationItems(): NavigationItem[] {
			return [
				{
					label: 'Séances',
					to: 'seances',
					icon: icons.seanceIcon
				},
				{
					label: 'Calendrier',
					to: 'calendrier',
					icon: icons.calendrierIcon
				},
				{
					label: 'Profil',
					to: 'profil',
					icon: icons.profilIcon
				}
			];
		}
	}
</script>
