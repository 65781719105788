<template>
	<div>
		<MainNavigation />
		<RouterView />
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import MainNavigation from '@/components/MainNavigation.vue';

	@Component ({
		components: {
			MainNavigation
		}
	})
	export default class Home extends Vue {}
</script>
