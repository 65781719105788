const cookieService = {
	/**
	 * Déclare un cookie
	 *
	 * @param {String} cookieName Le nom du cookie à déclarer
	 * @param {String} cookieValue Le contenu du cookie à déclarer
	 * @param {Number} expiration Le timestamp de validité du cookie
	 */
	set(cookieName: string, cookieValue: string, expiration: number): void {
		const date = new Date();
		date.setTime(expiration * 1000);
		const expires = 'expires=' + date.toUTCString();

		document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/; secure; samesite=lax`;
	},

	/**
	 * Récupère le contenu d'un cookie
	 *
	 * @param {String} cookieName Le nom du cookie à récupérer
	 * @returns {String} Le contenu du cookie
	 */
	get(cookieName: string): string {
		const name = cookieName + '=';
		const ca = document.cookie.split(';');

		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];

			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}

			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}

		return '';
	},

	/**
	 * Supprime un cookie
	 * @param {String} cookieName Le nom du cookie à supprimer
	 */
	remove(cookieName: string): void {
		document.cookie = `${cookieName}=; -1; path=/`;
	},

	/**
	 * Détermine si un cookie est présent
	 * @param {String} cookieName Le nom du cookie à vérifier
	 * @returns {Boolean} Vrai si le cookie existe
	 */
	exists(cookieName: string): boolean {
		const dc = document.cookie;
		const regex = new RegExp(`${cookieName}=`, 'g');

		return dc.search(regex) !== -1;
	}
};

export default cookieService;
