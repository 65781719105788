<template>
	<FooterBar
		:version="versionLabel"
		hide-a11y-link
		hide-cgu-link
		hide-legal-notice-link
		hide-sitemap-link
		hide-logo
		hide-social-media-links
		hide-cookies-link
	>
		<template #append>
			<VSwitch
				:value="mode"
				color="#1976D2"
				inset
				class="my-2 mx-4"
				label="Mode sombre"
				@change="toggleDarkMode"
			/>
		</template>
	</FooterBar>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import { LocalStorageUtility } from '@cnamts/vue-dot/src/helpers/localStorageUtility';

	@Component
	export default class AppFooter extends Vue {
		version = process.env.VUE_APP_VERSION;
		versionDate = process.env.VUE_APP_VERSION_DATE;
		mode = false;

		localStorageUtility = new LocalStorageUtility();

		get versionLabel(): string {
			return this.version + ' de ' + this.versionDate;
		}

		toggleDarkMode(): void {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			this.localStorageUtility.setItem('dark-mode', this.$vuetify.theme.dark);
		}

		mounted(): void {
			setTimeout(() => {
				if (this.localStorageUtility.getItem('dark-mode')) {
					this.$vuetify.theme.dark = true;
					this.mode = this.$vuetify.theme.dark;
				}
			},1);
		}
	}
</script>
