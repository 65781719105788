<template>
	<HeaderBar
		service-title="ConcertMP PS"
		theme="risque-pro"
	>
		<div class="text-right">
			<div
				v-if="!tokenService.isExpired()"
				class="pr-3"
			>
				Temps avant la déconnexion : {{ counterDuration }}
			</div>

			<UserMenuBtn
				v-if="!$maintenanceEnabled && user"
				:vuetify-options="userMenuBtnOptions"
				class="text-left"
				:full-name="userFullName"
				@logout="logout"
			/>
		</div>
	</HeaderBar>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { mapActions, mapState } from 'vuex';
	import Component from 'vue-class-component';

	import { updateAppState } from '@/router/authentication';

	import userInfoService from '@/services/authent/userInfo';
	import tokenService from '@/services/authent/token';

	import { LocalStorageUtility } from '@cnamts/vue-dot/src/helpers/localStorageUtility';

	@Component({
		computed: mapState('appState', ['user']),
		methods: mapActions('appState', ['updateCounterDuration'])
	})
	export default class AppHeader extends Vue {
		tokenService = tokenService;

		userMenuBtnOptions = {
			menu: {
				maxWidth: 'auto'
			},
			icon: {
				color: 'primary'
			}
		};

		get userFullName(): string {
			return this.user.prenom + ' ' + this.user.nom;
		}

		get counterDuration(): string {
			const counterDuration = this.$store.state.appState.counterDuration;

			if (counterDuration === 0) {
				return '< 1 minute';
			}

			return `${counterDuration} minute${counterDuration > 1 ? 's' : ''}`;
		}

		logout(): void {
			tokenService.delete();
			userInfoService.delete();
			updateAppState(null, false, false);

			this.updateCounterDuration(this.$store.getters['appState/getTimeLimit']);

			const ls = new LocalStorageUtility();
			const darkTheme = this.$vuetify.theme.dark;

			ls.clear();
			ls.setItem('dark-mode', darkTheme);

			this.$router.push({ name: 'loggedOut' });
		}
	}
</script>

<style>
	.vd-header-bar-container.w-100 > header > div > div {
		padding: 4px !important;
	}

	.vd-header-bar-container.w-100 > header {
		box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%) !important;
	}
</style>
